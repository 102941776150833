<template>
  <div id="managePilot" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Manage Pilot</div>
        <v-btn class="theme-btn-even" @click.stop="addItem()">
          <span class="I-create"></span>
          <span>Add Pilot</span>
        </v-btn>
      </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="items">
          <template v-slot:item.action="{ item }" class="text-xs-right">
            <div class="b-action">
              <div class="b-btn">
                <v-btn fab class="btn-edit" @click="editItem(item)">
                  <span class="I-edit"></span>
                </v-btn>
                <span style="padding-left: 8px;">
                <v-btn fab class="btn-edit" @click="editPilotExperienceSummary(item)" style="background-color: brown; padding-left: 5px;">
                  <span class="I-report"></span>
                </v-btn>
              </span>
                <v-btn fab class="primary btn-delete" @click="deleteItem(item)">
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- dialog สำหรับ เพิ่ม แก้ไข -->
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform step">
            <div class="t-namepage">{{ formTitle }} Pilot</div>
            <div class="b-form">
              <v-stepper v-model="stepdialog">
                <v-stepper-header>
                  <v-stepper-step :complete="stepdialog > 1" step="1"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 2" step="2"></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- Start step 1 select passenger -->
                  <v-stepper-content step="1">
                    <div class="b-contentstep">

                      <div class="B-carddetail B-formMain">
                        <div class="b-row">
                          <div class="box-S4">
                            <v-text-field v-model="formModel.pl_prefix" label="Title"></v-text-field>
                          </div>
                        </div>
                        <div class="b-row">
                          <div class="box-S4">
                            <v-text-field v-model="formModel.pl_name" label="Full Name"></v-text-field>
                          </div>
                        </div>
                        <div class="b-row">
                          <div class="box-S2">
                            <v-text-field v-model="formModel.pl_short_name" label="Short Name"></v-text-field>
                          </div>
                          <div class="box-S2">
                            <v-text-field v-model="formModel.pilot_rank" label="Rank" type="number"></v-text-field>
                          </div>
                        </div>
                        <div class="b-row">
                          <div class="box-S2">
                            <v-text-field v-model="formModel.pl_weight_kg" label="Weight (kg)" v-on:keyup="ConvertKgToLb()" type="number"></v-text-field>
                          </div>
                          <div class="box-S2">
                            <v-text-field v-model="formModel.pl_weight_lb" label="Weight (lb)" type="number" disabled></v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-ok Bsize100" text @click="stepdialog = 2">
                        <span class="T-size-18">next</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- Start step 1 select passenger -->
                  <v-stepper-content step="2">
                    <div class="b-contentstep">
                      <div class="B-carddetail B-formMain">
                        <div class="b-row">
                          <div class="box-S4">
                            <v-text-field v-model="formModel.pl_id_card_no" label="ID Card No." type="number"></v-text-field>
                          </div>
                        </div>
                        <div class="b-row">
                          <div class="box-S2">
                            <v-text-field v-model="formModel.pl_position" label="Position"></v-text-field>
                          </div>
                          <div class="box-S2">
                            <v-text-field v-model="formModel.pl_department" label="Department"></v-text-field>
                          </div>
                        </div>
                        <div class="b-row">
                          <div class="box-S4">
                            <v-select class="hideMessage" :items="itemsListGender" label="Gender" v-model="formModel.pl_gender"></v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="stepdialog = 1">
                        <span class="T-size-18">Back</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text @click="saveToServer()">
                        <span class="T-size-18">Save</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- dialog สำหรับลบ -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Confirmation</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>Delete "{{ formModel[Object.keys(formModel)[1]] }}" ?</v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="loading" @click="deleteToServer">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.b-row .box-S2 {
  margin: 0px 0px;
}
</style>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
export default {
  data: () => ({
    headers: [
      { value: "pl_name", text: "Pilot", sortable: true },
      { value: "action", text: "", sortable: false }
    ],
    defaultValue: {
      pl_prefix:"",
      pl_name: "",
      pl_short_name:"",
      pilot_rank:null,
      pl_weight_kg:0,
      pl_weight_lb:0,
      pl_weight_last_update:new Date(),
      pl_id_card_no:null,
      pl_position:"",
      pl_department:"",
      pl_gender:"Male",
    },
    query: { $sort: { Id: -1 } },
    //--end config

    items: [],
    loading: false,
    dialog: false,
    dialogDelete: false,
    formModel: {
      pl_gender:"Male"
    },
    mode: "", // มีได้ 2 แบบคือ create กับ edit
    stepdialog: 1,
    itemsListGender:["Male","Fremale"]
  }),
  computed: {
    formTitle() {
      return this.mode === "create" ? "Add" : "Edit";
    }
  },
  async mounted() {
    //init here
    this.renderUI();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderUI() {
      try {
        const q = {};
        q.retire = 0;
        var res = await feathersClientUOA.service("pilot").find({ query: q });
        this.items = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async addItem() {
      this.formModel = Object.assign({}, this.defaultValue);
      this.mode = "create";
      this.stepdialog = 1;
      this.dialog = true;
    },

    async editItem(item) {
      this.formModel = Object.assign({}, item);
      this.mode = "edit";
      this.stepdialog = 1;
      this.dialog = true;
    },
    async editPilotExperienceSummary(item) {
      this.$router.push({ path: `/managepilotedit/${item.pl_id}` });
    },
    async deleteItem(item) {
      this.formModel = Object.assign({}, item);
      this.dialogDelete = true;
    },

    closeDialog() {
      this.stepdialog = 1;
      this.dialog = false;
      this.dialogDelete = false;
    },
    async saveToServer() {
      this.loading = true;
      if (this.mode === "edit") {
        try {
          let dataEdit = {
            pl_prefix: this.formModel.pl_prefix,
            pl_name: this.formModel.pl_name,
            pl_short_name:this.formModel.pl_short_name,
            pilot_rank:this.formModel.pilot_rank,
            pl_weight_kg:this.formModel.pl_weight_kg,
            pl_weight_lb:this.formModel.pl_weight_lb,
            pl_weight_last_update:new Date(),
            pl_id_card_no:this.formModel.pl_id_card_no,
            pl_position:this.formModel.pl_position,
            pl_department:this.formModel.pl_department,
            pl_gender:this.formModel.pl_gender,
            md: new Date()
          };
          await feathersClientUOA
            .service("pilot")
            .patch(this.formModel.pl_id, dataEdit);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        //Add Data
        try {
          let dataAdd = {
            pl_prefix: this.formModel.pl_prefix,
            pl_name: this.formModel.pl_name,
            pl_short_name:this.formModel.pl_short_name,
            pilot_rank:this.formModel.pilot_rank,
            pl_weight_kg:this.formModel.pl_weight_kg,
            pl_weight_lb:this.formModel.pl_weight_lb,
            pl_weight_last_update:new Date(),
            pl_id_card_no:this.formModel.pl_id_card_no,
            pl_position:this.formModel.pl_position,
            pl_department:this.formModel.pl_department,
            pl_gender:this.formModel.pl_gender,
            cd: new Date()
          };
          await feathersClientUOA.service("pilot").create(dataAdd);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
      this.closeDialog();
    },
    async deleteToServer() {
      this.loading = true;
      try {
        let dataDelete = {
          mb: "GEM 2",
          md: new Date(),
          retire: 1
        };
        await feathersClientUOA
          .service("pilot")
          .patch(this.formModel.pl_id, dataDelete);
        this.renderUI();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    },
    ConvertKgToLb(){
      this.formModel.pl_weight_lb = null;
      this.formModel.pl_weight_lb = (this.formModel.pl_weight_kg*2.2046).toFixed(2);
    }
  }
};
</script>